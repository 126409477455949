<template>
  <div>
    <b-card title="Fotoğraf Galerisi">
      <b-carousel
        v-if="gallery.length > 0"
        id="photos-slider"
        controls
        indicators
      >
        <b-carousel-slide
          v-for="item in gallery"
          :key="item.id"
          :img-src="baseurl + '/media/portal/gallery/' + item.cover"
          class="card-img-overlay"
        >
          <b-link :to="'fotograf-galerisi/' + item.id">
            <h4 class="text-white">
              {{ item.title }}
            </h4>
            <p>{{ item.content }}</p>
          </b-link>
        </b-carousel-slide>
      </b-carousel>
      <empty-alert
        v-else
        text="Fotoğraf galerisine henüz fotoğraf bulunamadı."
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCarousel,
  BCarouselSlide,
  BLink,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'Photos',
  components: {
    BCarousel,
    BCarouselSlide,
    BCard,
    BLink,
    EmptyAlert,
  },
  data() {
    return {
      baseurl: this.$store.state.app.baseURL,
    }
  },
  computed: {
    gallery() {
      return this.$store.getters['gallery/getGallery']
    },
  },
  created() {
    this.getGallery()
  },
  methods: {
    getGallery() {
      this.$store.dispatch('gallery/gallery', {
        limit: 10,
      })
    },
  },
}
</script>

<style>
#photos-slider a {
  color: #fff
}
#photos-slider img {
  height: 600px;
  object-fit: cover;
  object-position: center;
}
</style>
