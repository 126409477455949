<template>
  <div>
    <b-card title="Yeni Başlayan Personel">
      <b-carousel
        v-if="newEmployee.length > 0"
        id="awards-carousel"
        :interval="5000"
      >
        <b-carousel-slide
          v-for="employee in newEmployee"
          :key="employee.id"
        >
          <a
            v-b-modal.modal-employee
            @click="setModal(employee)"
          >
            <b-card>
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    v-if="employee.image"
                    :src="baseurl + '/media/hr/employees/' + employee.image"
                    size="64"
                  />
                  <b-avatar
                    v-else
                    :text="employee.name[0]+employee.surname[0]"
                    size="64"
                    variant="info"
                  />
                </template>
                <h4 class="media-heading">
                  {{ employee.name }}
                  {{ employee.surname }}
                  <span class="font-small-1 d-block text-primary">{{ employee.title }}</span>
                </h4>
                <b-card-text>
                  <div v-html="employee.resume" />
                </b-card-text>
              </b-media>
            </b-card>
          </a>
        </b-carousel-slide>
      </b-carousel>
      <empty-alert
        v-else
        text="Personel bilgisi bulunamadı!"
      />
    </b-card>
    <b-modal
      id="modal-employee"
      centered
      size="lg"
      :title="modalData.name + ' ' + modalData.surname"
      ok-only
      ok-title="Kapat"
    >
      <b-card-text>
        <div class="text-center mb-1">
          <b-avatar
            v-if="modalData.image"
            :src="baseurl + '/media/hr/employees/' + modalData.image"
            size="120"
          />
          <b-avatar
            v-else
            :text="modalData.avatar"
            size="120"
            variant="info"
          />
        </div>
        <div v-html="modalData.resume" />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BMedia,
  BImg,
  BAvatar,
  BCardText,
  BModal,
  BCarousel,
  BCarouselSlide,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'NewEmployee',
  components: {
    BCard,
    BMedia,
    BImg,
    BAvatar,
    BCardText,
    BModal,
    BCarousel,
    BCarouselSlide,
    EmptyAlert,
  },
  data() {
    return {
      baseurl: this.$store.state.app.baseURL,
      modalData: {
        id: null,
        name: null,
        surname: null,
        image: null,
        avatar: null,
        resume: null,
      },
    }
  },
  computed: {
    newEmployee() {
      return this.$store.getters['newEmployees/getNewEmployees']
    },
  },
  created() {
    this.getNewEmployees()
  },
  methods: {
    setModal(item) {
      this.modalData = {
        id: item.id,
        name: item.name,
        surname: item.surname,
        image: item.image,
        avatar: item.name[0] + item.surname[0],
        resume: item.resume,
      }
    },
    getNewEmployees() {
      this.$store.dispatch('newEmployees/newEmployees')
    },
  },
}
</script>

<style scoped>
</style>
