<template>
  <b-card
    v-if="poll.id"
    no-body
    class="custom-card"
  >
    <b-card-text>
      <div>
        <b-card-title class="mb-0">
          Tek Soruluk Anket
        </b-card-title>
        <div v-if="poll">
          <div class="text-body font-small-2">
            Aşağıdaki tek soruluk anketi cevaplayarak gelişmemize yardımcı olun.
          </div>
          <div class="text-danger font-weight-bold mt-2">
            {{ poll.question }}
          </div>
          <div
            v-if="poll.voted === 0"
          >
            <b-form-radio-group
              v-model="selected"
              :options="poll.options"
              text-field="title"
              value-field="id"
              class="inline-spacing mt-1 mb-2 pt-0"
              name="radio-inline"
            />
            <div
              class="mb-1"
            >
              <b-form-textarea
                v-model="comment"
                size="sm"
                placeholder="Görüşlerinizi yazabilirsiniz"
                :disabled="!selected"
              />
            </div>
            <b-button
              :disabled="!selected"
              variant="primary"
              block
              @click="submitVote"
            >
              Gönder
            </b-button>
          </div>
          <div
            v-else
            class="business-items"
          >
            <div
              v-for="result in pollResult.options"
              :key="result.id"
            >
              <label>{{ result.title }}</label>
              <b-progress
                max="100"
                variant="info"
                class="progress-bar-info"
              >
                <b-progress-bar :value="result.percent">
                  <strong>% {{ result.percent }}</strong>
                </b-progress-bar>
              </b-progress>
            </div>
          </div>
        </div>
        <empty-alert
          v-else
          text="Güncel anket bulunmamaktadır."
        />
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardBody, BCardText, BFormRadioGroup, BFormGroup, BButton, BProgress, BProgressBar, BFormTextarea,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BCardText,
    BFormRadioGroup,
    BFormGroup,
    BButton,
    BProgress,
    BProgressBar,
    BFormTextarea,
    EmptyAlert,
  },
  data() {
    return {
      machineCode: null,
      selected: null,
      comment: null,
      options: [
        { text: 'Memnunum', value: 'first' },
        { text: 'Memnun Değilim', value: 'second' },
      ],
    }
  },
  computed: {
    poll() {
      const data = this.$store.getters['poll/getPoll']
      return data
    },
    pollResult() {
      return this.$store.getters['poll/getPollResult']
    },
  },
  created() {
    this.setMachineCode()
    this.getPoll()
    this.getPollResult()
  },
  methods: {
    getPoll() {
      this.$store.dispatch('poll/poll', { machine_code: this.machineCode })
    },
    getPollResult() {
      if (this.poll.id) {
        this.$store.dispatch('poll/pollResult', this.poll.id)
      }
    },
    submitVote() {
      const data = {
        id_com_portal_poll_options: this.selected,
        id_com_portal_polls: this.poll.id,
        machine_code: this.machineCode,
        comment: this.comment,
      }
      this.$store.dispatch('poll/pollVote', data)
    },
    setMachineCode() {
      if (!localStorage.getItem('machineCode')) {
        const machineCode = Math.floor(Math.random() * 12345678) * Math.floor(Math.random() * 99999999)
        localStorage.setItem('machineCode', machineCode)
        this.machineCode = machineCode
      } else {
        this.machineCode = localStorage.getItem('machineCode')
      }
    },
  },
}
</script>
<style scoped>
.custom-card{
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
  overflow: hidden;
  background-image: url("/media/portal/card-bg/poll-bg.jpg");
}
.custom-card .card-text{
  background-image: linear-gradient( 108.1deg,  rgba(167,220,225,0.25) 11.2%, rgba(217,239,242,0) 88.9% );
  width: 100%;
  height: 100%;
  margin: 0 !important;
  color: #1b315d;
  padding: 20px;
}
</style>
