<template>
  <b-card
    title="Ödüller"
  >
    <b-carousel
      v-if="awards.length > 0"
      id="awards-carousel"
      :interval="5000"
    >
      <b-carousel-slide
        v-for="award in awards"
        :key="award.id"
      >
        <div>
          <b-row>
            <b-col cols="8">
              <h4>{{ award.title }}</h4>
              <b-card-text v-html="award.content" />
            </b-col>
            <b-col
              cols="4"
              class="text-right"
            >
              <b-img
                v-if="!award.image"
                src="/media/portal/illustration/badge.svg"
                style="height: 150px"
                :alt="award.title"
              />
              <b-img
                v-else
                :src="baseurl + '/media/portal/awards/' + award.image"
                class="ml-1"
                style="height: 150px"
                rounded
                :alt="award.title"
              />
            </b-col>
          </b-row>
        </div>
      </b-carousel-slide>
    </b-carousel>
    <empty-alert
      v-else
      text="Güncel ödül bilgisi bulunamadı."
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BCarousel,
  BCarouselSlide,
  BImg,
  BCardText,
  BRow,
  BCol,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'EmployeeOfMonth',
  components: {
    BCarousel,
    BCarouselSlide,
    BCard,
    BImg,
    BCardText,
    BRow,
    BCol,
    EmptyAlert,
  },
  data() {
    return {
      baseurl: this.$store.state.app.baseURL,
    }
  },
  computed: {
    awards() {
      return this.$store.getters['awards/getAwards']
    },
  },
  created() {
    this.getAwards()
  },
  methods: {
    getAwards() {
      this.$store.dispatch('awards/awards', {
        limit: 5,
        order_by: [
          'com_portal_awards.id', 'DESC',
        ],
      })
    },
  },
}
</script>

<style>
#awards-carousel .carousel-caption {
  position: static !important;
  text-align: left;
  padding: 0;
}
body #awards-carousel .carousel-caption p {
  color: #6e6b7b;
  margin: 0;
}
#awards-carousel .carousel-control-next {
  right: -40px
}
#awards-carousel .carousel-control-prev {
  left: -40px;
}
.dark-layout #awards-carousel .carousel-caption p {
  color: #b4b7bd;
}
body #awards-carousel .carousel-control-next-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black"%20stroke-width="2"%20stroke-linecap="round"%20stroke-linejoin="round"%20class="feather%20feather-chevron-right"><polyline%20points="9%2018%2015%2012%209%206"></polyline></svg>');
}
.dark-layout #awards-carousel .carousel-control-next-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white"%20stroke-width="2"%20stroke-linecap="round"%20stroke-linejoin="round"%20class="feather%20feather-chevron-right"><polyline%20points="9%2018%2015%2012%209%206"></polyline></svg>');
}
body #awards-carousel .carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>');
}
.dark-layout #awards-carousel .carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>');
}
</style>
