<template>
  <b-card
    class="custom-card"
    no-body
  >
    <b-card-text v-if="today">
      <blockquote class="blockquote m-0 p-0">
        <p class="mb-0">
          {{ today.content }}
        </p>
        <footer class="blockquote-footer">
          {{ today.name }}
        </footer>
      </blockquote>
    </b-card-text>
    <empty-alert
      v-else
      text="Henüz günün motivasyon sözü eklenmemiş"
    />
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'QuoteOfDay',
  components: {
    BCard,
    BCardText,
    EmptyAlert,
  },
  computed: {
    today() {
      return this.$store.getters['quoteOfDays/getToday']
    },
  },
  created() {
    this.getToday()
  },
  methods: {
    getToday() {
      this.$store.dispatch('quoteOfDays/today')
    },
  },
}
</script>
<style scoped>
.custom-card{
  background-size: cover;
  background-position: center center;
  background-image: url('/media/portal/card-bg/quote-of-day-bg.jpg');
  overflow: hidden;
}
.custom-card .card-text{
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(2,37,78,0.1) 0%, rgba(4,56,126,0.1) 19.7%, rgba(85,245,221,0.1) 100.2% );
  width: 100%;
  height: 100%;
  margin: 0 !important;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  text-shadow: 0 2px 3px rgba(2, 15, 128, 0.35);
  padding: 30px;
}
.custom-card .card-text .blockquote{
  border-left: 3px solid rgb(255, 31, 31,0.75);
  padding-left: 10px !important;
}
.custom-card .card-text .blockquote-footer{
  text-align: left;
  color: #ffffff;
}
</style>
