<template>
  <b-card>
    <b-card-title>Fırsat Araçlar</b-card-title>
    <b-carousel
      v-if="cars"
      id="campaign-carousel"
      controls
      indicators
    >
      <b-carousel-slide
        v-for="(items,key) in cars"
        :key="key"
      >
        <template v-slot:img>
          <b-row>
            <b-col
              v-for="(item,key) in items"
              :key="item.id"
              cols="12"
              md="4"
              class="mb-3"
            >
              <a
                :href="item.url"
                target="_blank"
              >
                <b-card
                  :img-src="'https://inallar.com.tr/media/color/' + item.image"
                  img-top
                  no-body
                  class="p-1"
                  :class="key > 0? 'border-left' : ''"
                >
                  <b-card-body class="mt-1">
                    <b-card-text>
                      <div class="myear font-small-4 text-muted">
                        {{ item.myear }}
                      </div>
                      <div class="title font-weight-bold text-dark font-medium-2">
                        {{ item.brand }} {{ item.model }}
                      </div>
                      <div class="sub-title text-body">
                        {{ item.engine }} {{ item.hardware_package }}
                      </div>
                      <div class="fuel-gear font-small-3 text-warning mb-1">
                        {{ item.fuel }} - {{ item.gear }}
                      </div>
                      <div class="list-price font-weight-bolder font-medium-1">
                        {{ item.list_price | toCurrency }} ₺
                      </div>
                      <div class="campaign-price font-weight-bolder font-medium-5 text-danger mb-1">
                        {{ item.campaign_price | toCurrency }} ₺
                      </div>
                      <div class="gain-price font-weight-bold font-medium-1 bg-light text-center p-1 rounded text-warning">
                        <FeatherIcon
                          icon="CheckCircleIcon"
                          size="16"
                        /> {{ (item.list_price - item.campaign_price) | toCurrency }} ₺ İNDİRİM
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-card>
              </a>
            </b-col>
          </b-row>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <empty-alert
      v-else
      text="Güncel kampanya bulunmamaktadır."
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardBody,
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BCardText,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'Campaigns',
  components: {
    BCarousel,
    BCarouselSlide,
    BCard,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    EmptyAlert,
  },
  computed: {
    cars() {
      return this.$store.getters['campaigns/getCars']
    },
  },
  created() {
    this.getCars()
  },
  methods: {
    getCars() {
      this.$store.dispatch('campaigns/cars')
    },
  },
}
</script>

<style>
body #campaign-carousel .carousel-indicators li {
  background-color: #000
}
.dark-layout #campaign-carousel .carousel-indicators li {
  background-color: #fff
}
.list-price{
  text-decoration: line-through #FF0000;
}
</style>
