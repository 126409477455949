<template>
  <b-card
    class="custom-card"
    :style="'height:' + getHeight() + 'px'"
    no-body
  >
    <b-card-text>
      <div class="w-100">
        <div>
          <b-card-title class="mb-0">
            Yemek Menüsü<br><small class="text-muted">Bugün ve Yarın</small>
          </b-card-title>
        </div>
        <b-carousel
          v-if="meals"
          id="meal-carousel"
          :interval="5000"
        >
          <b-carousel-slide>
            <b-list-group class="bg-transparent border-0 border-right-0 border-left-0">
              <b-list-group-item class="bg-transparent border-top-0 pl-0 border-right-0 border-left-0">
                <div class="font-weight-bold text-primary">
                  {{ moment().format('LL') }}
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-for="(meal,index) in meals.today"
                :key="meal.id"
                class="d-flex align-items-center bg-transparent border-right-0 border-left-0 pl-0"
                :class="meals.today.length - 1 === index? 'border-bottom-0' : ''"
              >
                <span class="mr-1">
                  <b-img
                    src="/media/portal/meal-icon.png"
                    width="20"
                    height="20"
                  />
                </span>
                <span>{{ meal.title }}</span>
              </b-list-group-item>
            </b-list-group>
          </b-carousel-slide>
          <b-carousel-slide>
            <b-list-group class="bg-transparent border-0 border-right-0 border-left-0">
              <b-list-group-item class="bg-transparent border-top-0 pl-0 border-right-0 border-left-0">
                <div class="font-weight-bold text-primary">
                  {{ moment().add('days', +1).format('LL') }}
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-for="(yMeal,index) in meals.tomorrow"
                :key="yMeal.id"
                class="d-flex align-items-center bg-transparent border-right-0 border-left-0 pl-0"
                :class="meals.tomorrow.length - 1 === index? 'border-bottom-0' : ''"
              >
                <span class="mr-1">
                  <b-img
                    src="/media/portal/meal-icon.png"
                    width="20"
                    height="20"
                  />
                </span>
                <span>{{ yMeal.title }}</span>
              </b-list-group-item>
            </b-list-group>
          </b-carousel-slide>
        </b-carousel>
        <empty-alert
          v-else
          text="Güncel yemek menüsü henüz yüklenmemiş."
        />
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BListGroup, BListGroupItem, BCard, BCardTitle, BCarousel, BCarouselSlide, BCardText, BImg,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BCard,
    BCardTitle,
    BCarousel,
    BCarouselSlide,
    BCardText,
    BImg,
    EmptyAlert,
  },
  computed: {
    meals() {
      return this.$store.getters['meals/getMeals']
    },
  },
  created() {
    this.getMeals()
  },
  methods: {
    getMeals() {
      this.$store.dispatch('meals/meals', {
        where: {
          'com_portal_meals.mdate': this.moment().format('YYYY-MM-DD'),
        },
      })
    },
    getHeight() {
      let height = 'auto'
      if (this.meals) {
        if (this.meals.today.length > this.meals.tomorrow.length) {
          height = (this.meals.today.length * 42) + 120
        } else {
          height = (this.meals.tomorrow.length * 42) + 120
        }
      }
      return height
    },
  },
}
</script>
<style scoped>
.custom-card{
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
  overflow: hidden;
  background-image: url("/media/portal/card-bg/meal-bg.jpg");
}
.custom-card .card-text{
  background-image: linear-gradient( 174.2deg,  rgba(255,244,228,0.35) 7.1%, rgba(240,246,238,0) 67.4% );
  width: 100%;
  height: 100%;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 20px;
}
</style>
<style>
#meal-carousel .carousel-caption {
  position: static !important;
  text-align: left;
  padding: 0;
}
#meal-carousel span {
  font-size: 12px;
  color: #000;
}

</style>
