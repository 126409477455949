<template>
  <b-card
    no-body
    class="custom-card"
    type="button"
    @click="openPage"
  >
    <b-card-text>
      <div class="w-100 text-center">
        <div>
          {{ dataItem.title }}
        </div>
        <div class="subtitle">
          {{ dataItem.subtitle }}
        </div>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText,
} from 'bootstrap-vue'

export default {
  name: 'Organization',
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      folderUrl: `${this.$store.state.app.baseURL}/media/portal/documents/`,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['organization/companyOrganization']
    },
  },
  created() {
    this.getCompanyOrganization()
  },
  methods: {
    getCompanyOrganization() {
      this.$store.dispatch('organization/getCompanyOrganization')
    },
    openPage() {
      const fileUrl = this.folderUrl + this.dataItem.file
      window.open(fileUrl, '_blank')
    },
  },
}
</script>
<style scoped>
.custom-card{
  background-size: cover;
  background-position: center center;
  background-image: url('/media/portal/card-bg/company-organization-bg.jpg');
  overflow: hidden;
}
.custom-card .card-text{
  background-color: rgba(116, 235, 213,0.2);
  background-image: linear-gradient(90deg, rgba(116, 235, 213,0.2) 0%, rgba(159, 172, 230,0.2) 100%);
  width: 100%;
  height: 100%;
  margin: 0 !important;
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  text-shadow: 0 2px 3px rgba(2, 15, 128, 0.35);
  padding-top: 30px;
  padding-bottom: 30px;
}
.custom-card .card-text .subtitle{
  font-size: 12px;
}
</style>
