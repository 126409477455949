<template>
  <div v-if="notices.length > 0">
    <!--    <h3>Duyurular</h3>-->
    <!--    <hr>-->
    <b-card class="custom-bg">
      <b-carousel
        id="notification-carousel"
        :interval="5000"
      >
        <b-carousel-slide
          v-for="notice in notices"
          :key="notice.id"
        >
          <template #default>
            <a
              v-b-modal.modal-center
              href="#"
              @click="setModal(notice)"
            >
              <div class="text-container text-center">
                <h3 class="font-weight-bolder">{{ notice.title }}</h3>
                <p>{{ notice.content }}</p>
              </div>
            </a>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </b-card>
    <b-modal
      id="modal-center"
      centered
      :title="modalData.title"
      ok-only
      size="lg"
      ok-title="Kapat"
    >
      <b-card-text>
        <div>
          {{ modalData.content }}
        </div>
        <hr v-if="modalData.image">
        <b-img
          v-if="modalData.image"
          :src="modalData.image"
          fluid
          class="mt-1"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCarousel,
  BCarouselSlide,
  BModal,
  BCardText,
  BImg,
  VBModal,
} from 'bootstrap-vue'

export default {
  name: 'Notifications',
  components: {
    BCarousel,
    BCarouselSlide,
    BCard,
    BCardText,
    BModal,
    BImg,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      modalData: {
        title: null,
        content: null,
        image: null,
      },
    }
  },
  computed: {
    notices() {
      return this.$store.getters['notices/getNotices']
    },
  },
  created() {
    this.getNotices()
  },
  methods: {
    getNotices() {
      this.$store.dispatch('notices/notices', {
        where: {
          'com_portal_notices.status': 1,
          'com_portal_notices.edate >=': this.moment().format('YYYY-MM-DD'),
        },
      })
    },
    setModal(data) {
      if (data.image) {
        data.image = `${this.$store.state.app.baseURL}/media/portal/notices/${data.image}`
      }
      this.modalData.title = data.title
      this.modalData.content = data.content
      this.modalData.image = data.image
    },
  },
}
</script>

<style scoped>
.custom-bg {
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(234,249,249,0.67) 0.1%, rgba(239,249,251,0.63) 90.1% );
}
</style>

<style>
#notification-carousel .carousel-caption {
  position: static !important;
  text-align: left;
  padding: 0;
}
#notification-carousel h3 {
  font-size: 16px;
}
#notification-carousel p {
  margin: 0;
}
#notification-carousel .text-container h3{
  font-weight: 600;
  color: #1a2b57;
}
#notification-carousel .text-container p{
  font-size: 16px !important;
  color: #1a2b57;
}
</style>
