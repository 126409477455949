<template>
  <b-card
    title="Bugün Doğanlar"
    no-body
    class="custom-card"
  >
    <b-card-text>
      <b-carousel
        v-if="birthdays.length > 0"
        id="birthday-carousel"
        :interval="5000"
      >
        <b-carousel-slide
          v-for="birthday in birthdays"
          :key="birthday.id"
        >
          <b-media vertical-align="center">
            <template #aside>
              <div class="image-card">
                <b-avatar
                  v-if="birthday.image"
                  :src="birthday.image"
                  size="90"
                />
                <b-img
                  v-else
                  src="/media/portal/cake.jpg"
                  blank-color="#ccc"
                  width="90"
                  rounded="circle"
                />
              </div>
            </template>
            <h4 class="media-heading">
              <div class="name">
                {{ birthday.name }} {{ birthday.surname }}
              </div>
              <div class="title">
                {{ birthday.title }}
              </div>
            </h4>
            <div class="message-text">
              <p>{{ birthday.content }}</p>
            </div>
          </b-media>
        </b-carousel-slide>
      </b-carousel>
      <template v-else>
        <div class="empty-text">
          Bugün doğum günü olan<br>hiç personelimiz yok. :)
        </div>
      </template>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard,
  BCarousel,
  BCarouselSlide,
  BImg,
  BCardText,
  BMedia,
  BAvatar,
} from 'bootstrap-vue'
// import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'Birthday',
  components: {
    BCarousel,
    BCarouselSlide,
    BCard,
    BImg,
    BCardText,
    BMedia,
    BAvatar,
    // EmptyAlert,
  },
  computed: {
    birthdays() {
      return this.$store.getters['birthdays/getBirthdays']
    },
  },
  created() {
    this.getBirthdays()
  },
  methods: {
    getBirthdays() {
      this.$store.dispatch('birthdays/birthdays')
    },
  },
}
</script>
<style scoped>
.custom-card{
  background-size: cover;
  background-position: center center;
  background-image: url('/media/portal/card-bg/birthday-bg.jpg');
  overflow: hidden;
}
.custom-card .card-text{
  background-image: linear-gradient( 109.6deg,  rgba(112,246,255,0.65) 11.2%, rgba(221,108,241,0.55) 42%, rgba(229,106,253,0.95) 71.5%, rgba(123,183,253,0.50) 100.2% );
  width: 100%;
  height: 100%;
  margin: 0 !important;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 20px;
}
.custom-card .empty-text{
  text-align: center;
  font-size: 18px;
  padding: 20px;
}
</style>

<style>
#birthday-carousel{
  display: flex;
  align-items: center !important;
}
#birthday-carousel .carousel-caption {
  position: static !important;
  text-align: left;
  padding: 0;
  width: 100%;
  height: 100%;
}
#birthday-carousel .image-card{
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.35);
}
#birthday-carousel .carousel-caption .media-heading .name{
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 0 2px 3px rgba(2, 15, 128, 0.35) !important;
}
#birthday-carousel .carousel-caption .media-heading .title{
  font-size: 12px;
  color: rgb(255, 255, 255);
  font-style: italic;
  width: auto;
  font-weight: bold;
  text-shadow: 0 2px 3px rgba(2, 15, 128, 0.35) !important;
}
#birthday-carousel .carousel-caption p {
  color: #ffffff;
  margin: 0;
  text-shadow: 0 2px 3px rgba(2, 15, 128, 0.35) !important;
}
#birthday-carousel .carousel-control-next {
  right: 0px;
}
#birthday-carousel .carousel-control-prev {
  left: 0px;
}
#birthday-carousel .congratulation-medal {
  top: -15px;
}
.dark-layout #birthday-carousel-carousel .carousel-caption p {
  color: #b4b7bd;
}
body #birthday-carousel .carousel-control-next-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black"%20stroke-width="2"%20stroke-linecap="round"%20stroke-linejoin="round"%20class="feather%20feather-chevron-right"><polyline%20points="9%2018%2015%2012%209%206"></polyline></svg>');
}
.dark-layout #birthday-carousel .carousel-control-next-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white"%20stroke-width="2"%20stroke-linecap="round"%20stroke-linejoin="round"%20class="feather%20feather-chevron-right"><polyline%20points="9%2018%2015%2012%209%206"></polyline></svg>');
}
body #birthday-carousel .carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>');
}
.dark-layout #birthday-carousel .carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>');
}
</style>
