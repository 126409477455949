<template>
  <b-card
    class="h-100"
    title="Sigorta ve Anlaşmalı Kurumlar"
  >
    <b-carousel v-if="insurances.length > 0">
      <b-carousel-slide
        v-for="(insurance,key) in insurances"
        :key="key"
      >
        <template v-slot:img>
          <b-row class="match-height">
            <b-col
              v-for="item in insurance"
              :key="item.id"
              cols="12"
              md="4"
              class="align-middle mt-auto mb-auto"
            >
              <b-img
                :src="'https://inallar.com.tr/media/service_insurance/' + item.image"
                fuild
                center
                width="200"
              />
            </b-col>
          </b-row>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <empty-alert
      v-else
      text="Hata! Veri Yüklenemedi."
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'Insurance',
  components: {
    BCarousel,
    BCarouselSlide,
    BCard,
    BRow,
    BCol,
    BImg,
    EmptyAlert,
  },
  computed: {
    insurances() {
      return this.$store.getters['insurances/getInsurances']
    },
  },
  created() {
    this.getInsurances()
  },
  methods: {
    getInsurances() {
      this.$store.dispatch('insurances/insurances')
    },
  },
}
</script>

<style scoped>

</style>
