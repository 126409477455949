<template>
  <div v-if="campaigns.length > 0">
    <b-card>
      <b-card-title>Kampanyalar</b-card-title>
      <b-carousel
        v-if="campaigns"
        id="campaign-carousel"
        controls
        indicators
      >
        <b-carousel-slide
          v-for="(campaign,key) in campaigns"
          :key="key"
        >
          <template v-slot:img>
            <b-row>
              <b-col
                v-for="item in campaign"
                :key="item.id"
                cols="12"
                md="4"
                class="mb-3"
              >
                <a
                  :href="item.url"
                  target="_blank"
                >
                  <b-card
                    :img-src="'https://inallar.com.tr/media/campaign/' + item.image"
                    img-top
                    no-body
                  >
                    <b-card-body class="mt-1">
                      <b-card-title>
                        {{ item.title }}
                      </b-card-title>
                      <b-card-text>
                        {{ item.summary }}
                      </b-card-text>
                    </b-card-body>
                  </b-card>
                </a>
              </b-col>
            </b-row>
          </template>
        </b-carousel-slide>
      </b-carousel>
      <empty-alert
        v-else
        text="Güncel kampanya bulunmamaktadır."
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardBody,
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BCardText,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'Campaigns',
  components: {
    BCarousel,
    BCarouselSlide,
    BCard,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    EmptyAlert,
  },
  computed: {
    campaigns() {
      return this.$store.getters['campaigns/getCampaigns']
    },
  },
  created() {
    this.getCampaigns()
  },
  methods: {
    getCampaigns() {
      this.$store.dispatch('campaigns/campaigns')
    },
  },
}
</script>

<style>
body #campaign-carousel .carousel-indicators li {
  background-color: #000
}
.dark-layout #campaign-carousel .carousel-indicators li {
  background-color: #fff
}
</style>
