<template>
  <b-card
    title="Personel İlanları ve Açık Pozisyonlar"
    class="h-100"
  >
    <b-carousel
      v-if="career.length > 0"
      id="career-carousel"
      controls
      :interval="5000"
    >
      <b-carousel-slide
        v-for="item in career"
        :key="item.id"
        class="card-img-overlay"
      >
        <template #default>
          <h3>{{ item.title }}</h3>
          <p>{{ item.content }}</p>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <empty-alert
      v-else
      text="Şirketimiz bünyesinde açık pozisyon ve personel ilanı bulunmamaktadır."
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BCarousel,
  BCarouselSlide,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'Career',
  components: {
    BCarousel,
    BCarouselSlide,
    BCard,
    EmptyAlert,
  },
  computed: {
    career() {
      return this.$store.getters['career/getCareer']
    },
  },
  created() {
    this.getCareer()
  },
  methods: {
    getCareer() {
      this.$store.dispatch('career/career', {
        where: {
          'com_portal_career.status': 1,
        },
      })
    },
  },
}
</script>

<style>
#career-carousel .carousel-caption {
  position: static !important;
  text-align: left;
  padding: 0;
}
body #career-carousel h3 {
  font-size: 16px;
  color: #6e6b7b;
}
.dark-layout #career-carousel h3 {
  font-size: 16px;
  color: #b4b7bd;
}
#career-carousel p {
  font-size: 12px;
  margin: 0;
}
body #career-carousel .carousel-caption p {
  color: #6e6b7b;
  margin: 0;
}
.dark-layout #career-carousel .carousel-caption p {
  color: #b4b7bd;
}
#career-carousel .carousel-control-next {
  right: -60px
}
#career-carousel .carousel-control-prev {
  left: -60px;
}
body #career-carousel .carousel-control-next-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black"%20stroke-width="2"%20stroke-linecap="round"%20stroke-linejoin="round"%20class="feather%20feather-chevron-right"><polyline%20points="9%2018%2015%2012%209%206"></polyline></svg>');
}
.dark-layout #career-carousel .carousel-control-next-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white"%20stroke-width="2"%20stroke-linecap="round"%20stroke-linejoin="round"%20class="feather%20feather-chevron-right"><polyline%20points="9%2018%2015%2012%209%206"></polyline></svg>');
}
body #career-carousel .carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>');
}
.dark-layout #career-carousel .carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>');
}

@media(max-width: 768px) {
  .carousel-control-prev-icon, .carousel-control-next-icon {
    display: none;
  }
}
</style>
