<template>
  <b-card
    class="custom-card"
    no-body
  >
    <b-card-text>
      <div class="w-100">
        <b-card-title class="mb-2">
          Etkinlik Takvimi
        </b-card-title>
        <app-timeline v-if="events.length > 0">
          <app-timeline-item
            v-for="(event,key) in events"
            :key="event.id"
            :title="event.title"
            :subtitle="event.summary"
            :icon="event.icon"
            :time="moment(event.edate).format('DD.MM.YYYY')"
            :variant="colors[key]"
          />
        </app-timeline>
        <empty-alert
          v-else
          text="Henüz planlanmış etkinlik bulunmamaktadır."
        />
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { BCard, BCardTitle, BCardText } from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    EmptyAlert,
    BCard,
    BCardTitle,
    BCardText,
  },
  data() {
    return {
      colors: ['success', 'danger', 'info', 'warning', 'primary'],
    }
  },
  computed: {
    events() {
      return this.$store.getters['events/getEvents']
    },
  },
  created() {
    this.getEvents()
  },
  methods: {
    getEvents() {
      this.$store.dispatch('events/events', {
        where: { 'com_portal_events.edate >=': this.moment().format('YYYY-MM-DD') },
        order_by: ['com_portal_events.edate', 'ASC'],
        limit: 3,
      })
    },
  },
}
</script>
<style scoped>
.custom-card{
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
  overflow: hidden;
  background-image: url("/media/portal/card-bg/events-bg.jpg");
}
.custom-card .card-text{
  background-image: linear-gradient( 109.6deg,  rgba(223,234,247,0.25) 11.2%, rgba(244,248,252,0) 91.1% );
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 20px;
}
</style>
