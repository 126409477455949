<template>
  <b-card title="Doktor Takvimi">
    <b-table-simple
      hover
      small
      caption-top
      responsive
      bordered
    >
      <b-thead>
        <b-tr>
          <b-th
            width="500"
          >
            DR. ALİ ERDAL BAÇÇIOĞLU
          </b-th>
          <b-th
            width="250"
            class="text-center"
          >
            Merkez Şube
          </b-th>
          <b-th
            width="250"
            class="text-center"
          >
            Makyağsan
          </b-th>
          <b-th
            width="250"
            class="text-center"
          >
            Hyundai
          </b-th>
          <b-th
            width="250"
            class="text-center"
          >
            Özlüce
          </b-th>
          <b-th
            width="250"
            class="text-center"
          >
            Bosch Car
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>Pazartesi</b-td>
          <b-td class="text-center">
            14.00-16.00
          </b-td>
          <b-td class="text-center">
            -
          </b-td>
          <b-td class="text-center">
            11.15-12.15
          </b-td>
          <b-td class="text-center">
            12.45-13.30
          </b-td>
          <b-td
            rowspan="6"
            class="text-center font-small-2"
          >
            <strong>Ayın Son ÇARŞAMBA Günü</strong> <br> 15.00-15.30 Saatleri Arasında
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Salı</b-td>
          <b-td class="text-center">
            -
          </b-td>
          <b-td class="text-center">
            -
          </b-td>
          <b-td class="text-center">
            -
          </b-td>
          <b-td class="text-center">
            -
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Çarşamba</b-td>
          <b-td class="text-center">
            09.45-11.45
          </b-td>
          <b-td class="text-center">
            09.00-09.30
          </b-td>
          <b-td class="text-center">
            12.30-13.30
          </b-td>
          <b-td class="text-center">
            13.45-14.30
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Perşembe</b-td>
          <b-td class="text-center">
            -
          </b-td>
          <b-td class="text-center">
            -
          </b-td>
          <b-td class="text-center">
            -
          </b-td>
          <b-td class="text-center">
            -
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Cuma</b-td>
          <b-td class="text-center">
            -
          </b-td>
          <b-td class="text-center">
            -
          </b-td>
          <b-td class="text-center">
            -
          </b-td>
          <b-td class="text-center">
            -
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Cumartesi</b-td>
          <b-td class="text-center">
            -
          </b-td>
          <b-td class="text-center">
            -
          </b-td>
          <b-td class="text-center">
            -
          </b-td>
          <b-td class="text-center">
            -
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>

<script>
import {
  BCard, BTableSimple, BThead, BTbody, BTr, BTd, BTh,
} from 'bootstrap-vue'

export default {
  name: 'DoctorCalendar',
  components: {
    BCard,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
  },
}
</script>

<style scoped>
.tss{

}
</style>
