<template>
  <b-card title="İnallar Personeline Özel Anlaşmalı Kurumlar">
    <b-carousel
      v-if="companies.length > 0"
      id="contracted-companies-carousel"
      :interval="5000"
    >
      <b-carousel-slide
        v-for="company in companies"
        :key="company.id"
      >
        <b-row class="match-height">
          <b-col cols="auto">
            <b-img
              :src="folderUrl + company.image"
              class="logo mt-auto mb-auto"
              width="120"
            />
          </b-col>
          <b-col>
            <p class="mt-auto mb-auto">
              {{ company.content }}
            </p>
          </b-col>
        </b-row>
      </b-carousel-slide>
    </b-carousel>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCarousel, BCarouselSlide, BImg,
} from 'bootstrap-vue'

export default {
  name: 'ContractedCompanies',
  components: {
    BRow,
    BCol,
    BCard,
    BCarousel,
    BCarouselSlide,
    BImg,
  },
  data() {
    return {
      folderUrl: `${this.$store.state.app.baseURL}/media/portal/contracted_companies/`,
    }
  },
  computed: {
    companies() {
      return this.$store.getters['contractedCompanies/getContractedCompanies']
    },
  },
  created() {
    this.getCompanies()
  },
  methods: {
    getCompanies() {
      this.$store.dispatch('contractedCompanies/contractedCompanies')
    },
  },
}
</script>

<style>
#contracted-companies-carousel .carousel-caption {
  position: static !important;
  text-align: left;
  padding: 0;
}
body #contracted-companies-carousel .carousel-caption p {
  color: #6e6b7b;
  margin: 0;
}
#contracted-companies-carousel .carousel-control-next {
  right: -40px
}
#contracted-companies-carousel .carousel-control-prev {
  left: -40px;
}
.dark-layout #contracted-companies-carousel .carousel-caption p {
  color: #000000 !important;
}
body #contracted-companies-carousel .carousel-control-next-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black"%20stroke-width="2"%20stroke-linecap="round"%20stroke-linejoin="round"%20class="feather%20feather-chevron-right"><polyline%20points="9%2018%2015%2012%209%206"></polyline></svg>');
}
.dark-layout #contracted-companies-carousel .carousel-control-next-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white"%20stroke-width="2"%20stroke-linecap="round"%20stroke-linejoin="round"%20class="feather%20feather-chevron-right"><polyline%20points="9%2018%2015%2012%209%206"></polyline></svg>');
}
body #contracted-companies-carousel .carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>');
}
.dark-layout #contracted-companies-carousel .carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>');
}
</style>
