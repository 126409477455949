<template>
  <b-card title="BES ve Tamamlayıcı Sağlık Sigortası">
    <b-row>
      <b-col class="bes text-center">
        <div class="height-100">
          <b-img
            src="media/portal/axa-sigorta.svg"
            class="width-200"
          />
        </div>
        <div class="font-medium-2 font-weight-bold">
          Bireysel Emeklilik Poliçesi
        </div>
        <b-button
          v-b-modal.modal-bes
          variant="primary"
          class="mt-1"
        >
          Detaylı Bilgi
        </b-button>
      </b-col>
      <b-col>
        <div class="tss text-center">
          <div class="height-100">
            <b-img
              src="media/portal/ana-sigorta.svg"
              class="width-150"
            />
          </div>
          <div class="font-medium-2 font-weight-bold">
            Tamamlayıcı Sağlık Sigortası
          </div>
          <b-button
            v-b-modal.modal-tss
            variant="primary"
            class="mt-1"
          >
            Detaylı Bilgi
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="modal-bes"
      centered
      size="lg"
      :title="bes.title"
      ok-only
      ok-title="Kapat"
    >
      <b-card-text>
        <div class="text-center">
          <b-img
            src="media/portal/axa-sigorta.svg"
            class="width-300"
          />
        </div>
        <div v-html="bes.content" />
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-tss"
      centered
      size="lg"
      :title="tss.title"
      ok-only
      ok-title="Kapat"
    >
      <b-card-text>
        <div class="text-center">
          <b-img
            src="media/portal/ana-sigorta.svg"
            class="width-200"
          />
        </div>
        <div v-html="tss.content" />
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BImg, BButton, BModal,
} from 'bootstrap-vue'

export default {
  name: 'Bes',
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BButton,
    BModal,
  },
  data() {
    return {
      bes: {
        title: 'Bireysel Emeklilik Poliçesi',
        content: '<div><p>📌 İşe başlayan her personele otomatik katılım olarak poliçesi yapılır.</p><p>📌 Yapılan birikimin fon yönetimi kişinin kendi sorumluluğunda olup AXA FIT uygulamasını indirerek toplam ödeme ve birikiminizi görebilir, fon değişimi yapabilirsiniz.</p><p>📌 Tüm bilgilere 0850 250 99 99 nolu telefondan ulaşabilirsiniz.</p></div>',
      },
      tss: {
        title: 'Tamamlayıcı Sağlık Sigortası',
        content: '<div><p>📌 Tamamlayıcı sağlık sigortamız ayakta tedavi doktor muayeneleri için yılda 10 hak sağlamaktadır. Ayakta tedaviler için hastanede 15 TL katılım payı alınmaktadır.</p><p>📌 Acil muayeneler de bu 10 haktan düşmektedir.</p><p>📌 Yatarak tedaviler ve ameliyatlar da herhangi bir katılım payı ve adet limit sınırlaması yoktur.</p><p>📌 GEÇMİŞTEN GELEN TANI KONMUŞ E NABIZ KAYDINIZA İŞLEMİŞ HİÇBİR RAHATSIZLIĞI ÖZEL SAĞLIK SİGORTASI KARŞILAMAZ , KAPSAM DIŞI KALIR.</p><p>📌 Kaç muayene hakkınız kaldığını öğrenmek için 0850 460 62 62 numaralı telefondan ulaşabilirsiniz.</p><p>📌 Eş ve çocuk eklenmekte olup primleri şirket tarafından karşılanmamaktadır.</p><p>📌 Poliçelerimizde ücretsiz olarak, yılda 1 kez check-up teminatı, diş tedavi paketi, online diyetisyen, psikolojik destek ve görüntülü doktor danışma hizmeti mevcut.</p><p>📌 Kullanım haklarınız dolunca maalesef muayene hakkı ekleyemiyorsunuz, poliçenin yenileme dönemini beklemek zorundasınız veya grup poliçesinden ayrı kendinize ek bir bireysel poliçe yapabilirsiniz.</p></div>',
      },
    }
  },
}
</script>

<style scoped>
.tss{

}
</style>
